import axios from '@axios'
import qs from 'qs'

export default {
    namespaced: true,
    state: {
        condition: {},
    },
    getters: {
        getCondition (state) {
            return state.condition
        },
    },
    mutations: {
        updateCondition (state, condition) {
            state.condition = condition
        },
    },
    actions: {
        search (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.get('/api/presalesorder/search', { params: params }).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        view (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.get('/api/presalesorder/view', { params: params }).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        edit (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.get('/app/presalesorder/edit', { params: params }).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        save (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorder/save', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        submit (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorder/submit', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },
        state (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/state', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        changeStatus (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/changeStatus', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        checkSuccess (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/checkSuccess', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        pushPresalesOrderToBBMall (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/bbmall/order/v1/push', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },
        cancelAudit (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/cancelAudit', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        addAttachments (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/addAttachments', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        isReceiptSuccess (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/isReceiptSuccess', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        isReceiptError (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/isReceiptError', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },
        lockCheck (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/lockCheck', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        outbound (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/outbound', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        saveRemark (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/saveRemark', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        exportData (ctx, params) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/api/exportexcelfile/exportData',
                    method: 'get',
                    params: params,
                    responseType: 'blob',
                }).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        //根据当前人获取团队列表
        loadStoreList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/storesales/getStoreByUserId', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        //根据团队获取所有业务员
        loadSalesManList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/store/getUserByStoreId', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        //根据团队获取所有客户
        loadChannelList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/company/search', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        //根据团队获取所有仓库
        loadWarehouseList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/warehouse/findWareHouseIdsByStoreIds', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        //根据仓库加载仓位
        loadLocationList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/warehouse/findWarehouseLocationByWarehouseId',
                    params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        loadProductList (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/stock/searchProductPC', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        addItems (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/addItems', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        addItemMemo (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/addMemo', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        updateItem (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/updateItem', params).
                    then(response => resolve(response)).
                    catch(error => reject(error))
            })
        },

        delItem (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/delItem', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },

        delItems (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/delItems', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },

        searchItem (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.get('/api/presalesorderitem/search',  { params: params }).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },

        delAllItem (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/app/presalesorderitem/delAllItem', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },

        savePreSalesOrder (ctx, params) {
            return new Promise((resolve, reject) => {
                axios.post('/api/presalesorder/savePreSalesOrder', params).
                then(response => resolve(response)).
                catch(error => reject(error))
            })
        },


    },

}
