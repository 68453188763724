<template>
  <b-card class="presalesorder-edit-wrapper">
    <b-form id="presalesorderForm">
      <b-row>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="团队名称"
              label-for="store_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="store_id"
                :options="storeSelectOptions"
                v-model="storeName"
                label="store_name"
                class="select-size-sm"
                placeholder="请选择业务团队"
                @input="changeSelect('store',$event)"
                :disabled='id !== 0'
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="终端客户"
              label-for="order_no"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="channel_name"
                :filterable="false"
                :options="channelSelectOptions"
                v-model="channelName"
                class="select-size-sm"
                label="company_name"
                placeholder="请选择终端客户，输入名称搜索"
                @input="changeSelect('channel',$event)"
                @search="loadChannelList"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="订货编号"
              label-for="order_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="order_no"
                size="sm"
                v-model="orderNo"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="业务人员"
              label-for="sales_name"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="sales_id"
                :options="salesManSelectOptions"
                v-model="salesName"
                label="full_name"
                @input="changeSelect('salesman',$event)"
                class="select-size-sm"
                placeholder="请选择业务员"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收货地址"
              label-for="order_no"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="channel_address"
                :filterable="false"
                :options="channelAddressSelectOptions"
                v-model="channelAddress"
                label="address"
                class="select-size-sm"
                placeholder="请选择收货地址"
                @input="changeSelect('channel_address',$event)"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="送货方式"
              label-for="delivery_type"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="pay_period"
                :options="deliveryTypeOptions"
                v-model="deliveryTypeLabel"
                @input="changeSelect('delivery_type',$event)"
                class="select-size-sm"
                placeholder="请选择送货方式"
                :clearable="true"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="销售日期"
              label-for="sales_time"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                v-model="salesTime"
                class="form-control"
                placeholder="请选择销售日期"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="发货仓库"
              label-for="sales_warehouse"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="sales_warehouse"
                :options="warehouseSelectOptions.length===0?[]:warehouseSelectOptions"
                v-model="salesWarehouseName"
                label="warehouse_name"
                @input="changeSelect('sales_warehouse',$event)"
                class="select-size-sm"
                placeholder="请选择发货仓库"
                :disabled='id !== 0'
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款周期"
              label-for="pay_period"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="payPeriodOptions"
                :clearable="false"
                v-model="payPeriodLabel"
                @input="changeSelect('pay_period',$event)"
                class="select-size-sm"
                placeholder="请选择付款周期"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="配送日期"
              label-for="sales_time"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                v-model="deliveryTime"
                class="form-control"
                placeholder="请选择配送日期"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div style="margin-top: 25px;width: 98.6%;margin-left: 0.7%">
        <b-card
            no-body
            class="mb-0 list-table"
            style="box-shadow: none"
        >
          <div class="m-2">

            <!-- Table Top -->
            <b-row>
              <b-col
                  cols="12"
                  md="6"
              >
                <v-select
                    id="product"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="productListSelect"
                    multiple
                    label="name"
                    class="flex-grow-1 email-to-selector"
                    :close-on-select="false"
                    :options="productList"
                    :filterable="false"
                    placeholder="请输入商品名称、69码搜索(请输入至少两个字符)"
                    @search="loadProductOptions"
                    :dropdown-should-open="openSelect"
                >

                  <template #option="{ location_full_name, name, ext, qty,year_and_month }">
                    <b-badge pill :variant="`light-${getCodeColor('warehouse_location',location_full_name)}`">
                      {{ getCodeLabel('warehouse_location', location_full_name) }}
                    </b-badge>
                    <span class="ml-50"> |  {{ name }} </span>
                    <span class="ml-50"> |  {{ ext.code }}</span>
                    <span class="ml-50"> |  可用库存: {{ qty }} </span>
                    <span class="ml-50"> |  生产日期: {{ year_and_month }} </span>
                  </template>

                  <template #selected-option="{ location_full_name, name }" style="background-color: cornsilk">
                    <b-badge pill :variant="`light-${getCodeColor('warehouse_location',location_full_name)}`">
                      {{ getCodeLabel('warehouse_location', location_full_name) }}
                    </b-badge>
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                  cols="12"
                  md="1"
              >
                <b-button variant="gradient-primary" @click="quickAdd">
                  加入明细
                </b-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <div class="block">
                  <span class="demonstration">商品效期</span>
                  <el-date-picker
                      v-model="monthSearch"
                      type="monthrange"
                      visible="true"
                      start-placeholder="开始月份"
                      end-placeholder="结束月份">
                  </el-date-picker>
                </div>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="ml-1"
                    @click="showExport"
                >
                  <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                  />
                  <span class="align-right">导入商品</span>
                </b-button>

              </b-col>
              <b-col
                  cols="12"
                  md="1"
              >
                <b-button v-if="checked.length > 0" variant="gradient-primary"
                          @click="delSelectItem">
                  删除选项
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-modal
              id="modal-stock-log"
              centered
              size="xl"
              title="下列商品已存在，如重复添加请输入新价格"
              ok-title="添加"
              cancel-title="不添加"
              @ok="addRepeatProduct"
              ref="addProducts"
          >
            <b-table
                class="position-relative"
                :items="repeatList"
                responsive
                hover
                small
                primary-key="id"
                :fields="repeatTableColumns"
                show-empty
                empty-text="未找到记录"
                v-model="table"
            >
              <template #cell(date)="data">
                <span>{{repeatList[data.index].stock_year}}-{{repeatList[data.index].stock_month}}</span>
              </template>
              <template #cell(sales_price)="data">
                <b-form-input
                    id="largeInput"
                    size="sm"
                    :type='"number"'
                    v-model="repeatList[data.index].sales_price"
                />
              </template>
            </b-table>
          </b-modal>

          <b-table
              ref="refListTable"
              class="position-relative"
              :items="itemList"
              responsive
              hover
              small
              primary-key="id"
              :fields="tableColumns"
              show-empty
              empty-text="未找到记录"
              v-model="table"
              :tbody-tr-class="rowClass"
          >
            <template v-slot:head(#)="data">
              <xy-checkbox
                  :checked="checked"
                  :allChecked.sync="allChecked"
                  :indeterminate="indeterminate"
                  :isCheckedObj.sync="isCheckedObj"
                  :table="table"
                  type="head"
                  :row="data"
              />
            </template>
            <template v-slot:cell(#)="row">
              <xy-checkbox
                  :checked="checked"
                  :allChecked.sync="allChecked"
                  :indeterminate="indeterminate"
                  :isCheckedObj="isCheckedObj"
                  :table="table"
                  type="row"
                  :row="row"
                  :rowChecked.sync="isCheckedObj['index'+row.item.id]"
              />
            </template>

            <template #cell(is_expenses)="data">
              <b-form-checkbox
                  name="is_invoice"
                  switch
                  inline
                  v-model="data.item.is_expenses"
                  value="1"
                  unchecked-value="0"
                  @input="changeIsExpenses($event,data.item)"
              />
            </template>

            <template #cell(sales_cost_type)="data">
              <v-select
                  :options="getCodeOptions('sales_cost_type')"
                  v-model="data.item.salesCostType"
                  class="select-size-sm"
                  @input="changeSalesCostType($event,data.item)"
              />
            </template>

            <template #cell(number)="data">
              <span>{{ data.index + 1 }}</span>
            </template>

            <template #cell(location_id)="data">
              <b-badge pill
                       :variant="`light-${getCodeColor('warehouse_location',itemList[data.index].ext.locationFullName)}`">
                {{ getCodeLabel('warehouse_location', itemList[data.index].ext.locationFullName) }}
              </b-badge>
            </template>

            <template #cell(stock_year)="data">
              {{ data.item.stock_year }}-{{ data.item.stock_month }}
            </template>

            <template #cell(stock)="data">
              <span>{{ itemList[data.index].ext.model.ext.canUseQty }}</span>
            </template>

            <template #cell(sales_qty)="data">
              <b-form-input
                  size="sm"
                  :type='"number"'
                  v-model="itemList[data.index].sales_qty"
                  @blur="updateItem(data.item,data.index,$event)"
              />
              <span v-if="itemList[data.index].sales_qty > itemList[data.index].ext.model.ext.canUseQty"
                    style="color: red;">可用库存不足！</span>
            </template>
            <template #cell(sales_price)="data">
              <b-form-input
                  size="sm"
                  :type='"number"'
                  v-model="itemList[data.index].sales_price"
                  @blur="updateItem(data.item,data.index,$event)"
              />
              <span v-if="itemList[data.index].sales_price === ''" style="color: red; size: A5">价格不能为空</span>
            </template>
            <template #cell(memo)="data">
              <b-form-input
                  size="sm"
                  v-model="itemList[data.index].memo"
                  @blur="addItemMemo(data.item,$event)"
              />
              <span
                  v-if="(itemList[data.index].sales_price === 0 || itemList[data.index].sales_price === '') && itemList[data.index].memo === ''"
                  style="color: red; size: A5">价格为0请添加备注</span>
            </template>
            <template #cell(delete)="data" v-if="readonly===0">
              <div style="display: flex">
                <b-button
                    variant="gradient-success"
                    style="margin: 2px; border-radius: 20px"
                    size="mini"
                    @click="openBatch(data.item)"
                >
                  <feather-icon icon="FilterIcon"></feather-icon>
                </b-button>
                <b-button
                    variant="gradient-warning"
                    style="margin: 2px; border-radius: 20px"
                    size="mini"
                    @click="delItem(itemList[data.index].item_id)"
                >
                  <feather-icon icon="XIcon"></feather-icon>
                </b-button>
              </div>
            </template>
          </b-table>

          <b-modal
              id="product-stock"
              ok-only
              ok-title="确认"
              @ok="onSelectSalesOrderStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
          >
            <stock-manage
                ref="productStockSelect"
                :warehouseid="salesWarehouse"
                :purchaseTeamUserId="salesId"
                :purchaseTeamStoreId="storeId"
                :productId="tempItem.product_id"
                :qty="parseInt(tempItem.sales_qty)"
                :productName="tempItem.name"
                :orderId="tempItem.order_id"
                :itemId="tempItem.item_id"
                :type="2"
                :location-full-name="tempItem.ext.locationFullName"
                :stockArray="productStockArray.filter(e=>e.item_id == tempItem.item_id)"
                :stockYear="tempItem.stock_year"
                :stockMonth="tempItem.stock_month"
            >
            </stock-manage>
          </b-modal>

          <div style="margin: 10px">
            <b-row>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">合计¥{{ subtotal }}  共{{ itemCount }}件</span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>

      <div style="margin-top: 20px">
        <b-col>
          <span style="font-weight: bolder">订单备注</span>
          <b-form-textarea placeholder="添加订单备注..." v-model="memo"/>
        </b-col>
      </div>
      <b-row>
        <b-col
            cols="12"
            class="mt-50"
            align="end"
        >
          <b-button
              variant="success"
              class="mr-1"
              @click="save"
              v-if="readonly===0"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
              v-if="readonly===0"
          >
            取消
          </b-button>
          <b-button
              variant="primary"
              class="mr-1"
              @click="submitOrder"
              v-if="readonly===0"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>


    <b-modal
        id="attachModal"
        centered
        title="商品导入"
        ref="attachModal"
        @ok="exportExcel"
        cancel-title="取消"

    >
      <attachment-upload :theme="'files'"
                         :object_type="'excel'"
                         @change="onUploaded"
      />
    </b-modal>

  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BMedia,
  BModal,
  BBadge,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import presalesorderStore from './presalesorderStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import {
  getCode,
  getCodeColor,
  getCodeLabel,
  getCodeOptions,
  second,
  toDate,
  isNumber,
  isEmpty
} from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import debounce from 'lodash.debounce'
import {getUserData} from "@/auth/utils";
import salesorderitemStore from "@/views/apps/offlinesalesorderitem/salesorderitemStore";
import XyCheckbox from "@/views/components/xy/XyCheckbox";
import presalesorderitemStore from "@/views/apps/presalesorderitem/presalesorderitemStore";
import StockManage from "@/views/apps/stock/product-stock/StockManage";
import ElDatePicker from "element-ui/packages/date-picker";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    AttachmentUpload,
    StockManage,
    XyCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BBadge,
    vSelect,
    useToast,
    ElDatePicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: ref(0),
      user: {},
      orderId: 0,
      orderNo: '',
      storeId: 0,
      storeName: '',//团队名称
      salesId: 0,//业务员id
      salesName: '',//业务员名称
      salesTime: '',//销售日期
      deliveryTime: '',//配送日期
      channelId: 0,//渠道客户id
      channelName: '',//渠道客户名称
      channelAddress: '',//渠道客户地址
      payPeriod: 0,//付款周期
      subtotal: 0.00,//总金额
      itemCount: 0,//总数量
      salesWarehouse: 0, //发货仓库id
      salesWarehouseName: '', //发货仓库名称
      deliveryType: 0, //配送方式
      status: '',//销售前置单订单状态   pre_order_status
      state: 0,//状态
      memo: '',
      preSalesOrder: {},
      readonly: 0,
      start: 1,
      limit: 40,
      searchKey: '',
      monthSearch: '',
      checked: [],
      allChecked: false,
      indeterminate: false,
      table: [],
      isCheckedObj: {},
      productList: [],
      productListSelect: [],
      loadingVisible: true,
      itemList: [],
      repeatTableColumns: [
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'date',
          label: '生产日期',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'sales_price',
          label: '新单价',
        },
      ],
      tableColumns: [
        {key: '#', label: '#'},
        {
          key: 'number',
          label: '序号',
        },
        {
          key: 'item_id',
          label: 'ID',
        },
        {
          key: 'location_id',
          label: '库位',
        },
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'stock_year',
          label: '生产日期',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'specification',
          label: '规格',
        },
        {
          key: 'avg_cost',
          label: '平均成本',
        },
        {
          key: 'stock',
          label: '可用库存',
        },
        {
          key: 'sales_qty',
          label: '数量',
        },
        {
          key: 'sales_price',
          label: '单价',
        },
        {
          key: 'is_expenses',
          label: '是费用',
        },
        {
          key: 'sales_cost_type',
          label: '费用科目',
        },
        {
          key: 'subtotal',
          label: '小计',
        },
        {
          key: 'memo',
          label: '备注',
        },
        {
          key: 'delete',
          label: '操作',
        },
      ],
      itemListShowFlag: true,
      storeSelectOptions: [],//团队下拉选项
      salesManSelectOptions: [],//业务员选项
      channelSelectOptions: [],//渠道客户选项
      channelAddressSelectOptions: [],//渠道客户地址选项
      payPeriodOptions: [],//付款周期选项
      payPeriodLabel: '',//付款周期label
      warehouseSelectOptions: [],//发货仓库选项
      deliveryTypeOptions: [],//配送方式选项
      deliveryTypeLabel: '',//配送方式label
      repeatList: [], //重复添加商品列表
      needUpdate: false,
      tempItem: {ext: {}},//选批次所选择的item
      productStockArray: [],//所选批次的item_id,stock_id
      rangeDate: '',
      excelSqlId:'',
      show:true
    }
  },
  setup() {
    const toast = useToast()

    if (!store.hasModule('presalesorder')) store.registerModule('presalesorder', presalesorderStore)
    if (!store.hasModule('offlinesalesorderitem')) store.registerModule('offlinesalesorderitem', salesorderitemStore)
    if (!store.hasModule('presalesorderitem')) store.registerModule('presalesorderitem', presalesorderitemStore)

    onUnmounted(() => {
      if (store.hasModule('presalesorder')) store.unregisterModule('presalesorder')
      if (store.hasModule('offlinesalesorderitem')) store.unregisterModule('offlinesalesorderitem')
      if (store.hasModule('presalesorderitem')) store.unregisterModule('presalesorderitem')
    })

    const reload = function () {
      store.dispatch('presalesorder/edit', {
        id: 0,
        storeId: this.storeId,
      }).then(res => {
        if (res.data.code === 0) {
          this.orderId = res.data.data.order_id
          this.orderNo = res.data.data.order_no
          this.payPeriod = this.payPeriodOptions[0].value
          this.payPeriodLabel = this.payPeriodOptions[0].label
          this.deliveryType = this.deliveryTypeOptions[0].value
          this.deliveryTypeLabel = this.deliveryTypeOptions[0].label
        } else {
          toast.error('订单加载失败')
        }
      })
    }
    const edit = function () {
      store.dispatch('presalesorder/edit', {
        id: this.orderId,
        storeId: this.storeId,
      }).then(res => {
        if (res.data.code === 0) {
          this.orderId = res.data.data.order_id
          this.orderNo = res.data.data.order_no
          this.storeName = res.data.data.store_name
          this.storeId = res.data.data.store_id
          this.salesId = res.data.data.sales_id
          this.salesName = res.data.data.sales_name
          this.salesTime = toDate(res.data.data.sales_time)
          this.deliveryTime = toDate(res.data.data.delivery_time)
          this.channelId = res.data.data.channel_id
          this.channelName = res.data.data.channel_name
          this.channelAddress = res.data.data.channel_address
          this.payPeriod = res.data.data.pay_period
          this.payPeriodLabel = getCodeLabel('preorder_pay_preiod', res.data.data.pay_period)
          this.salesWarehouse = res.data.data.sales_warehouse
          this.salesWarehouseName = res.data.data.ext.warehouseName
          this.deliveryType = res.data.data.delivery_type
          this.subtotal = res.data.data.subtotal
          this.itemCount = res.data.data.item_count
          this.memo = res.data.data.memo
          this.deliveryTypeLabel = getCodeLabel('preorder_delivery_method', res.data.data.delivery_type)
          this.detail()
        } else {
          toast.error('加载订单失败')
        }
      })
    }

    const detail = function () {
      store.dispatch('presalesorder/searchItem', {
        order_id: this.orderId,
        warehouseId: this.salesWarehouse,
        order_by: 'item_id',
        order_desc: 'desc',
        purchaseTeamUserId: this.salesId,
        purchaseTeamStoreId: this.storeId
      }).then(res => {
        if (res.data.code === 0) {
          this.itemList = res.data.data.ext.list
          for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].salesCostType = getCodeLabel('sales_cost_type',this.itemList[i].sales_cost_type)
          }
          this.subtotal = res.data.data.ext.price
          this.itemCount = res.data.data.ext.qty
        } else {
          toast.error('加载订单明细失败')
        }
      })
    }

    const view = function () {
      store.dispatch('presalesorder/view', {id: this.id}).then(res => {
        this.presalesorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const delItem = function (itemId) {
      store.dispatch('presalesorder/delItem', {
        orderId: this.orderId,
        itemId: itemId
      }).then(res => {
        if (res.data.code === 0) {
          this.detail()
        } else {
          toast.error('删除失败')
        }
      })
    }

    const openBatch = function (params) {
      this.tempItem = params
      this.$bvModal.show('product-stock')
    }

    const onSelectSalesOrderStock = function (params) {
      let checked = this.$refs.productStockSelect.getChecked();
      const array = []
      let sumQty = 0
      for (let i = 0; i < checked.length; i++) {
        array.push({
          stock_id: checked[i].stock_id,
          product_id: checked[i].product_id,
          qty: parseInt(checked[i].ext.useQty),
        })
        sumQty += parseInt(checked[i].ext.useQty);
      }
      if (this.tempItem.sales_qty != sumQty) {
        toast.error("数量不一致,无法保存批次!")
        return;
      }
      for (let i = 0; i < this.productStockArray.length; i++) {
        if (this.productStockArray[i].item_id === this.tempItem.item_id) {
          this.productStockArray.splice(i, 1)
        }
      }
      this.productStockArray.push({
        item_id: this.tempItem.item_id,
        array: array
      })
    }

    const delAllItem = function () {
      if (this.itemList.length > 0) {
        store.dispatch('presalesorder/delAllItem', {orderId: this.orderId}).then(res => {
          if (res.data.code === 0) {
            this.detail()
          } else {
            toast.error("清空失败")
          }
        })
      }
    }

    const delSelectItem = function () {
      let itemIdList = []
      for (let i = 0; i < this.checked.length; i++) {
        itemIdList.push(this.checked[i].item_id)
      }
      let itemIds = itemIdList.join(',')
      store.dispatch('presalesorder/delItems', {
        orderId: this.orderId,
        itemIds: itemIds
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('删除成功')
          this.checked = []
          this.detail()
        } else {
          toast.error('删除失败')
        }
      })
    }

    const save = function () {
      let err = 0
      let errMsg = ""
      const cRegExp = /^[1-9]\d*$/
      this.itemList.forEach(item => {
        if (isNumber(item.sales_price) === 0 && item.memo === '') {
          err = 1
          errMsg = "价格为0请添加备注!"
        }
        if (!cRegExp.test(item.sales_qty)) {
          errMsg = "数量必须是正整数!"
          err = 1
        }
        if (isNumber(item.sales_price) < 0) {
          errMsg = "价格不能为负数!"
          err = 1
        }
        if (item.is_expenses == 1&&isEmpty(item.sales_cost_type)){
          errMsg = "费用科目必填!"
          err = 1
        }
      })
      if (err !== 0) {
        toast.error(errMsg)
        return false
      }
      if (this.storeId === undefined || this.storeId === "" || this.storeId == null) {
        toast.error("请选择 团队!")
        return false
      }
      if (this.salesId === undefined || this.salesId === "" || this.salesId == null || this.salesId == 0) {
        toast.error("请选择 业务员!")
        return false
      }
      if (this.salesTime === undefined || this.salesTime === "") {
        toast.error("请填写  售卖时间!")
        return false
      }
      if (this.deliveryTime === undefined || this.deliveryTime === "") {
        toast.error("请填写  配送日期!")
        return false
      }
      if (this.channelId === undefined || this.channelId === "" || this.channelId == null || this.channelId == 0) {
        toast.error("请选择 渠道客户!")
        return false
      }
      if (this.salesWarehouse === undefined || this.salesWarehouse === "" || this.salesWarehouse == null || this.salesWarehouse == 0) {
        toast.error("请选择 发货仓库!")
        return false
      }
      if (this.payPeriod === undefined || this.payPeriod === "" || this.payPeriod == null) {
        toast.error("请选择 付款周期!")
        return false
      }
      if (this.salesTime > this.deliveryTime) {
        toast.error("配送日期必须大于等于售卖日期！")
        return false
      }

      let tempItemList = this.itemList
      let tempProductStockArray = this.productStockArray
      const itemIds = []
      const itemStockIds = []
      for (let i = 0; i < tempItemList.length; i++) {
        if (tempItemList[i].sales_qty === "" || tempItemList[i].sales_qty === 0) {
          toast.error("商品" + tempItemList[i].name + "未输入数量！")
          return false
        }
        itemIds.push(tempItemList[i].item_id)
        for (let j = 0; j < tempProductStockArray.length; j++) {
          if (tempProductStockArray[j].item_id == tempItemList[i].item_id) {
            itemStockIds.push(tempProductStockArray[j].array)
          }
        }
        if (itemStockIds[i] == null) {
          itemStockIds[i] = []
        }
      }

      this.preSalesOrder.itemIds = JSON.stringify(itemIds)
      this.preSalesOrder.itemStockIds = JSON.stringify(itemStockIds)
      this.preSalesOrder.order_id = this.orderId
      this.preSalesOrder.order_no = this.orderNo
      this.preSalesOrder.store_name = this.storeName
      this.preSalesOrder.store_id = this.storeId
      this.preSalesOrder.sales_id = this.salesId
      this.preSalesOrder.sales_name = this.salesName
      this.preSalesOrder.sales_time = second(this.salesTime)
      this.preSalesOrder.delivery_time = second(this.deliveryTime)
      this.preSalesOrder.channel_id = this.channelId
      this.preSalesOrder.channel_name = this.channelName
      this.preSalesOrder.channel_address = this.channelAddress
      this.preSalesOrder.pay_period = this.payPeriod
      this.preSalesOrder.subtotal = this.subtotal
      this.preSalesOrder.item_count = this.itemCount
      this.preSalesOrder.sales_warehouse = this.salesWarehouse
      this.preSalesOrder.sales_warehouse_name = this.salesWarehouseName
      this.preSalesOrder.delivery_type = this.deliveryType
      this.preSalesOrder.status = this.status
      this.preSalesOrder.state = this.state
      this.preSalesOrder.memo = this.memo
      store.dispatch('presalesorder/save', this.preSalesOrder).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-presalesorder-list'})
        } else {
          toast.error(res.data.data)
        }
      })
    }


    const submitOrder = function () {
      let err = 0
      let errMsg = ""
      const cRegExp = /^[1-9]\d*$/
      this.itemList.forEach(item => {
        if (isNumber(item.sales_price) === 0 && item.memo === '') {
          err = 1
          errMsg = "价格为0请添加备注!"
        }
        if (!cRegExp.test(item.sales_qty)) {
          errMsg = "数量必须是正整数!"
          err = 1
        }
        if (isNumber(item.sales_price) < 0) {
          errMsg = "价格不能为负数!"
          err = 1
        }
        if (item.is_expenses == 1&&isEmpty(item.sales_cost_type)){
          errMsg = "费用科目必填!"
          err = 1
        }
      })
      if (err !== 0) {
        toast.error(errMsg)
        return false
      }
      if (this.storeId === undefined || this.storeId === "" || this.storeId == null) {
        toast.error("请选择 团队!")
        return false
      }
      if (this.salesId === undefined || this.salesId === "" || this.salesId == null || this.salesId == 0) {
        toast.error("请选择 业务员!")
        return false
      }
      if (this.salesTime === undefined || this.salesTime === "") {
        toast.error("请填写  售卖时间!")
        return false
      }
      if (this.deliveryTime === undefined || this.deliveryTime === "") {
        toast.error("请填写  配送日期!")
        return false
      }
      if (this.channelId === undefined || this.channelId === "" || this.channelId == null || this.channelId == 0) {
        toast.error("请选择 渠道客户!")
        return false
      }
      if (this.salesWarehouse === undefined || this.salesWarehouse === "" || this.salesWarehouse == null || this.salesWarehouse == 0) {
        toast.error("请选择 发货仓库!")
        return false
      }
      if (this.payPeriod === undefined || this.payPeriod === "" || this.payPeriod == null) {
        toast.error("请选择 付款周期!")
        return false
      }
      if (this.salesTime > this.deliveryTime) {
        toast.error("配送日期必须大于等于售卖日期！")
        return false
      }

      let tempItemList = this.itemList
      let tempProductStockArray = this.productStockArray
      const itemIds = []
      const itemStockIds = []
      for (let i = 0; i < tempItemList.length; i++) {
        if (tempItemList[i].sales_qty === "" || tempItemList[i].sales_qty === 0) {
          toast.error("商品" + tempItemList[i].name + "未输入数量！")
          return false
        }
        itemIds.push(tempItemList[i].item_id)
        for (let j = 0; j < tempProductStockArray.length; j++) {
          if (tempProductStockArray[j].item_id == tempItemList[i].item_id) {
            itemStockIds.push(tempProductStockArray[j].array)
          }
        }
        if (itemStockIds[i] == null) {
          itemStockIds[i] = []
        }
      }

      this.preSalesOrder.itemIds = JSON.stringify(itemIds)
      this.preSalesOrder.itemStockIds = JSON.stringify(itemStockIds)
      this.preSalesOrder.order_id = this.orderId
      this.preSalesOrder.order_no = this.orderNo
      this.preSalesOrder.store_name = this.storeName
      this.preSalesOrder.store_id = this.storeId
      this.preSalesOrder.sales_id = this.salesId
      this.preSalesOrder.sales_name = this.salesName
      this.preSalesOrder.sales_time = second(this.salesTime)
      this.preSalesOrder.delivery_time = second(this.deliveryTime)
      this.preSalesOrder.channel_id = this.channelId
      this.preSalesOrder.channel_name = this.channelName
      this.preSalesOrder.channel_address = this.channelAddress
      this.preSalesOrder.pay_period = this.payPeriod
      this.preSalesOrder.subtotal = this.subtotal
      this.preSalesOrder.item_count = this.itemCount
      this.preSalesOrder.sales_warehouse = this.salesWarehouse
      this.preSalesOrder.sales_warehouse_name = this.salesWarehouseName
      this.preSalesOrder.delivery_type = this.deliveryType
      this.preSalesOrder.status = this.status
      this.preSalesOrder.state = this.state
      this.preSalesOrder.memo = this.memo
      store.dispatch('presalesorder/save', this.preSalesOrder).then(res => {
        if (res.data.code === 0) {
          store.dispatch('presalesorder/changeStatus', {
            id: parseInt(res.data.data),
            status: 1,
          }).then(res => {
            if (res.data.code === 0) {
              this.readonly = 1
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-presalesorder-list'})
            } else {
              toast.error(res.data.data)
              this.$router.push({name: 'apps-presalesorder-list'})
            }
          })
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const showModal = function () {
      this.$refs['choose-store-modal'].show()
    }

    //下拉框选择改变处理逻辑
    const changeSelect = function (key, event) {
      if (key === 'store') {
        if (this.storeId !== event.store_id && this.itemList.length > 0) {
          this.delAllItem()
        }
        this.storeId = event.store_id
        this.storeName = event.store_name
        // this.salesId = null
        // this.salesName = null
      } else if (key === 'salesman') {
        this.salesId = event.user_id
        this.salesName = event.full_name
      } else if (key === 'channel') {
        this.channelId = event.company_id
        this.channelName = event.company_name
        this.channelAddress = event.address
        this.channelAddressSelectOptions = event.ext.contactList
      } else if (key === 'channel_address') {
        this.channelAddress = event.address
      } else if (key === 'pay_period') {
        this.payPeriod = event.value
        this.payPeriodLabel = getCodeLabel('preorder_pay_preiod', event.value)
      } else if (key === 'sales_warehouse') {
        if (this.salesWarehouse != event.warehouse_id && this.itemList.length > 0) {
          this.delAllItem()
        }
        this.salesWarehouse = event.warehouse_id
        this.salesWarehouseName = event.warehouse_name
      } else if (key === 'delivery_type') {
        this.deliveryType = event.value
        this.deliveryTypeLabel = getCodeLabel('preorder_delivery_method', event.value)
      }

    }

    const loadStoreList = function () {
      store.dispatch('presalesorder/loadStoreList', {userId: this.user.user_id, type: 1}).then(res => {
        if (res.data.code !== 0) {
          toast.error('加载团队失败!')
          return
        }
        if (res.data.data.length < 1) {
          toast.error('您当前不属于任何团队!')
          return
        }
        this.storeSelectOptions = res.data.data
        if (this.orderId === 0) {
          this.storeId = res.data.data[0].store_id
          this.storeName = res.data.data[0].store_name
        }
      })
    }

    const loadSalesManList = function () {
      store.dispatch('presalesorder/loadSalesManList', {storeId: this.storeId}).then(res => {
        if (res.data.code !== 0) {
          this.salesManSelectOptions = []
          toast.error('加载业务员失败!')
          return
        }
        if (res.data.data.length < 1) {
          this.salesId = 0
          this.salesName = ''
          this.salesManSelectOptions = []
          return
        } else {
          this.salesManSelectOptions = res.data.data
          if (this.id === 0) {
            for (let i = 0; i < this.salesManSelectOptions.length; i++) {
              if (this.salesManSelectOptions[i].user_id === this.user.user_id) {
                this.salesName = getCodeLabel('user', this.user.user_id)
                this.salesId = this.user.user_id
                return
              }
            }
            this.salesId = 0
            this.salesName = ''
          }
        }
      })
      this.loadChannelList()
    }

    const loadChannelList = function (searchKey) {
      store.dispatch('presalesorder/loadChannelList', {
        type: 6,
        start: 1,
        limit: 20,
        storeId: this.storeId,
        status: 4,
        order_by: 'company_id',
        order_desc: 'desc',
        searchKey: searchKey == undefined ? '' : searchKey.replaceAll("'", ""),
        monthSearch: isEmpty(this.monthSearch) ? '' : this.monthSearch,
      }).then(res => {
        if (res.data.code === 0) {
          this.channelSelectOptions = res.data.data.list
        }
      })
    }

    const loadWarehouseOptions = function () {
      store.dispatch('presalesorder/loadWarehouseList', {storeId: this.storeId}).then(res => {
        if (res.data.code !== 0) {
          this.warehouseSelectOptions = []
          toast.error('加载仓库失败!')
          return
        }
        if (res.data.data.length < 1) {
          this.salesWarehouse = 0
          this.salesWarehouseName = ''
          this.warehouseSelectOptions = []
          return
        }
        this.warehouseSelectOptions = res.data.data
        if (this.id === 0) {
          this.salesWarehouse = res.data.data[0].warehouse_id
          this.salesWarehouseName = res.data.data[0].warehouse_name
        }
      })
    }

    const openSelect = function (VueSelect) {
      if (this.productList.length > 0) {
        return VueSelect.open
      }
      return VueSelect.search.length >= 2 && VueSelect.open
    }

    const loadProductOptions = debounce(function (searchKey) {
      if (searchKey !== '' && searchKey.length >= 2) {
        this.productList = []
        store.dispatch('presalesorder/loadProductList', {
          start: this.start,
          limit: this.limit,
          warehouseId: this.salesWarehouse,
          name: searchKey.replaceAll("'", ""),
          monthSearch: isEmpty(this.monthSearch) ? '' : this.monthSearch,
          channelId: this.channelId,
          qtyType: 1,
          purchaseTeamUserId: this.salesId,
          purchaseTeamStoreId: this.storeId,
          orderId: this.orderId,
        }).then(res => {
          if (res.data.code !== 0) {
            return
          }
          this.productList = res.data.data.list
        })
      }
    }, 500)

    const quickAdd = function () {
      if (this.channelId === 0) {
        toast.error('请选择 客户!')
        return false
      }
      if (this.productListSelect.length > 0) {
        let productIdList = []
        let locationIdList = []
        let salesPriceList = []
        let yearAndMonth = []
        for (let i = 0; i < this.productListSelect.length; i++) {
          productIdList.push(this.productListSelect[i].product_id)
          locationIdList.push(this.productListSelect[i].location_id)
          salesPriceList.push(this.productListSelect[i].ext.last_sales_price)
          yearAndMonth.push(this.productListSelect[i].year_and_month)
        }
        let productIds = productIdList.join(',')
        let locationIds = locationIdList.join(',')
        let salesPrices = salesPriceList.join(',')
        let yearAndMonths = yearAndMonth.join(',')
        store.dispatch('presalesorder/addItems', {
          orderId: this.orderId,
          warehouseId: this.salesWarehouse,
          productIds: productIds,
          locationIds: locationIds,
          salesPrices: salesPrices,
          yearAndMonths: yearAndMonths,
        }).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.ext.repeatList.length > 0) {
              this.repeatList = res.data.data.ext.repeatList
              this.$refs['addProducts'].show()
            } else {
              toast.success('添加成功')
            }
            this.productListSelect = []
            this.detail()
          } else {
            toast.error('添加失败')
          }
        })
        // var promise = Promise.resolve()
        // for (let i = 0; i < this.productListSelect.length; i++) {
        //   promise = promise.then(() => {
        //     return new Promise((resolve, reject) => {
        //       store.dispatch('presalesorder/addItem', {
        //         orderId: this.orderId,
        //         locationId: this.productListSelect[i].location_id,
        //         productId: this.productListSelect[i].product_id,
        //         salesQty: 1,
        //         salesPrice: this.productListSelect[i].ext.last_sales_price,
        //       }).then(res => {
        //         if (res.data.code === 0) {
        //         }
        //         resolve()
        //       })
        //     })
        //
        //   })
        // }
        // promise.then(() => {
        //   this.productListSelect = []
        //   this.detail()
        // })
      }
    }

    const addRepeatProduct = function () {
      let productIdList = []
      let locationIdList = []
      let salesPriceList = []
      let yearAndMonth = []
      for (let i = 0; i < this.repeatList.length; i++) {
        let month = ''
        if (this.repeatList[i].stock_month.length > 1) {
          month = this.repeatList[i].stock_month
        } else {
          month = "0" + this.repeatList[i].stock_month
        }
        productIdList.push(this.repeatList[i].product_id)
        locationIdList.push(this.repeatList[i].location_id)
        salesPriceList.push(this.repeatList[i].sales_price)
        yearAndMonth.push(this.repeatList[i].stock_year + "-" + month)
      }
      let productIds = productIdList.join(',')
      let locationIds = locationIdList.join(',')
      let salesPrices = salesPriceList.join(',')
      let yearAndMonths = yearAndMonth.join(',')
      store.dispatch('presalesorder/addItems', {
        orderId: this.orderId,
        warehouseId: this.salesWarehouse,
        productIds: productIds,
        locationIds: locationIds,
        salesPrices: salesPrices,
        yearAndMonths: yearAndMonths,
      }).then(res => {
        if (res.data.code === 0) {
          this.repeatList = []
          this.detail()
          if (res.data.data.ext.repeatList.length > 0) {
            this.repeatList = res.data.data.ext.repeatList
            this.$refs['addProducts'].show()
          } else {
            toast.success('添加成功')
          }
        } else {
          toast.error('添加失败')
        }
      })
    }

    const addItemMemo = function (item, event) {
      store.dispatch('presalesorder/addItemMemo', {
        itemId: item.item_id,
        memo: event.target.value,
      }).then(res => {
        if (res.data.code !== 0) {
          toast.error('添加备注失败')
        }
      })
    }

    const updateItem = function (item, index, event) {
      let err = 0
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(item.sales_qty)) {
        toast.error('售卖数量必须是正整数!')
        err = 1
      }
      if (isNumber(item.sales_price) < 0) {
        toast.error("价格不能为负数")
        err = 1
      }
      if (item.is_expenses == 1&&isEmpty(item.sales_cost_type)){
        toast.error("费用科目必填!")
        err = 1
      }
      if (err !== 0) {
        return false
      }
      store.dispatch('presalesorder/updateItem', {
        orderId: this.orderId,
        itemId: item.item_id,
        salesQty: item.sales_qty,
        salesPrice: item.sales_price,
        // productId: item.product_id,
        // canUseQty: item.ext.model.ext.canUseQty,
        // locationId: item.location_id
      }).then(res => {
        if (res.data.code === 0) {
          this.detail()
        } else {
          toast.error(res.data.data)
          this.detail()
        }
      })
    }
    const fromChildren = function (val) {
      this.itemList = val
    }

    const rowClass = function (item, type) {
      if (!item || type !== 'row') {
        return
      }
      if (item.ext.checkQty > item.ext.model.ext.canUseQty) {
        return 'table-danger'
      }
    }

    const changeIsExpenses = function (event, params) {
      store.dispatch('presalesorderitem/changeIsExpenses', {
        itemId: params.id,
        isExpenses: event,
      }).then(response => {
        if (response.data.code === 1) {
          toast.error("修改失败")
        }
      })
    }

    const changeSalesCostType = function (event, params) {
      params.sales_cost_type = isEmpty(event)?null:event.value
      this.$forceUpdate()
      store.dispatch('presalesorderitem/changeSalesCostType', {
        itemId: params.id,
        type: isEmpty(event)?null:event.value,
      }).then(response => {
        if (response.data.code === 1) {
          toast.error("修改失败")
        }
      })
    }

    const showExport = function () {
      this.excelSqlId = ''
      this.$refs['attachModal'].show()
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }

    const exportExcel = function () {
      this.show = false;
      const params = {
        excelSqlId: this.excelSqlId,
        purchaseTeamStoreId:this.storeId,
        orderId:this.orderId,
        warehouseId:this.salesWarehouse
      }
      if (this.excelSqlId === null || this.excelSqlId == "") {
        toast.error("请添加附件")
        this.show = true
        return false
      }
      store.dispatch('presalesorderitem/exportItem', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code === 0) {
              toast.success(data)
              this.detail()
            } else {
              toast.error(data)
            }
            this.show = true
          })
    }


    return {
      reload,
      edit,
      detail,
      view,
      cancel,
      save,
      submitOrder,
      delItem,
      openBatch,
      onSelectSalesOrderStock,
      delAllItem,
      delSelectItem,
      showModal,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelect,
      loadChannelList,
      loadStoreList,
      loadSalesManList,
      loadWarehouseOptions,
      loadProductOptions,
      quickAdd,
      addRepeatProduct,
      addItemMemo,
      updateItem,
      rowClass,
      openSelect,
      changeIsExpenses,
      changeSalesCostType,

      fromChildren,
      isEmpty,
      showExport,
      onUploaded,
      exportExcel,
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.storeId = this.$route.params.stroeId || 0
    this.orderId = this.$route.query.id || 0
    this.payPeriodOptions = getCodeOptions('preorder_pay_preiod')
    this.deliveryTypeOptions = getCodeOptions('preorder_delivery_method')
    const userData = getUserData()
    this.user = userData
    if (this.id == 0) {
      let dDate = new Date();
      this.salesTime = dDate
      this.deliveryTime = toDate(new Date().setDate(dDate.getDate() + 1) / 1000)
      // 新增 加载团队列表
      this.loadStoreList()
    } else {
      this.edit()
    }
    this.readonly = this.$route.query.readonly || 0;
    if (this.$route.query.readonly===0||this.id===0){
      this.readonly=0
    }
  },
  mounted() {

  },
  computed: {
    storeChange() {
      return {
        store_id: this.storeId,
        store_name: this.storeName,
      }
    },
    warehouseIdChange() {
      return {
        warehouse_id: this.salesWarehouse,
        warehouse_name: this.salesWarehouseName,
      }
    },
  },
  watch: {
    storeChange: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          //当团队发生改变
          if (this.id === 0) {
            // 新建状态 则重新生成单号 和订单id
            this.reload()
            this.channelName = ''
            this.channelId = 0
            this.channelAddress = ''
            this.channelSelectOptions = []
            this.channelAddressSelectOptions = []
            //加载团队下的仓库

          }
          this.loadWarehouseOptions()
          //加载团队下的业务员
          this.loadSalesManList()
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

