import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/presalesorderitem/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/presalesorderitem/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/presalesorderitem/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/presalesorderitem/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    modifyRefuseQty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/modifyRefuseQty', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    modifySalesQty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/modifySalesQty', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveBoxQuantity(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/saveBoxQuantity', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeIsExpenses(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/changeIsExpenses', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeIsAdjustPrice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/presalesorderitem/changeIsAdjustPrice', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeSalesCostType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/changeSalesCostType', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesorderitem/exportItem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

  },
}
